.AppConfirmDialog {
    cursor: pointer;
    color: @ccxBlack;
}

.AppConfirmDialogDisabled {
    cursor: not-allowed;
    color: @ccxGray;
}

.AppConfirmDialogCritical {
    cursor: pointer;
    color: @ccxRed;
}

.AppConfirmDialogCritical:hover {
    color: white;
}

.AppConfirmDialogAlertMessage {
    margin-left: 35px !important;
}

.AppConfirmDialogAlertDescription {
    margin-left: 35px !important;
}

@hack: true; @import "/src/src/antd-default-theme.less";