.WizardFormConfigurationStep1ConfigCard {
    padding: 1rem;
    min-height: 10rem;
}

.WizardFormConfigurationStep1ConfigOpt {
    padding: 0;
    margin-top: 0.3rem;
    color: rgba(0, 0, 0, 0.45);
}

.WizardFormConfigurationStep1ConfigCol {
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    margin-left: 0.5rem;
    min-width: 19rem;
    max-width: 19rem;
    border-radius: 16px;
    position: relative;

    &:hover {
        border: 1px solid @ccxBaseBlue;
    }
    
}

.WizardFormConfigurationColSelected{
    border: 1px solid @ccxBaseBlue;
}

.WizardFormConfigurationStep1ConfigCol:nth-child(2) {
    margin-right: 2rem;
}

.WizardFormConfigurationStep1ConfigRow {
    flex-direction: row-reverse;
}

.WizardFormConfigurationStep1MarkerGreen {
    background: #fafafa;
    position: absolute;
    padding: 2px 27px;
    left: 8rem;
    z-index: 5;
    color: #52c41a;
    top: -1rem;
    border: 1px solid #b7eb8f;
    border-radius: 1rem;
}

.WizardFormConfigurationStep1MarkerGrey {
    position: absolute;
    padding: 2px 27px;
    left: 8rem;
    z-index: 5;
    top: -1rem;
    border-radius: 1rem;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    color: #000000a6;
}

@hack: true; @import "/src/src/antd-default-theme.less";