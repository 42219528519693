.DatastoreScalingCardVolumeInfo {
    background: #f5f5f5;
    border-radius: 16px;
}

.DatastoreScalingCardVolumeRow {
    justify-content: center;
    align-items: center;
}

.DatastoreScalingCardVolumeCol {
    border-radius: @border-radius-base-x2;
    min-height: 3.5rem;
    background: #f5f5f5;
    padding: 1rem 1rem 0 1rem;
}

.DatastoreScalingCardVolumeCount {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #c41d7f;
}

@hack: true; @import "/src/src/antd-default-theme.less";