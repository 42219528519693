.AddServiceCreateDatastoreStepContainer {
    margin: 20px;

    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: @ccxBlack;
        text-align: center;
        margin-bottom: 30px;
    }

    input {
        border-radius: @border-radius-base-x2;
    }
}

.AddServiceCreateDatastoreStepItemGroup {
    width: 100%;
}

.AddServiceCreateDatastoreStepItem > * {
    width: 95%;
    height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;

    margin-bottom: 10px;

    border-radius: @border-radius-base-x2 !important;

    &:hover {
        border: 1px solid @ccxBaseBlue;
    }
}

.AddServiceCreateDatastoreStepItemBig > * {
    width: 95%;
    height: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;

    margin-bottom: 10px;

    border-radius: @border-radius-base-x2 !important;

    &:hover {
        border: 1px solid @ccxBaseBlue;
    }
}

.AddServiceCreateDatastoreDivider {
    margin-bottom: 0;
}

.AddServiceCreateDatastorePricing {
    font-size: 0.875rem;

    > *:nth-child(2) {
        color: @ccxGreen;
        font-weight: 500;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";