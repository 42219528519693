.AppRadioGroupGrid {
    width: 100%;
}

.AppRadioGridButton > * {
    width: 95%;
    height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-radius: @border-radius-base-x2 !important;

    &:hover {
        border: 1px solid @ccxBaseBlue;
    }
}

.AppRadioGridButtonLarge > * {
    height: fit-content;
}

@hack: true; @import "/src/src/antd-default-theme.less";