.DatastoreScalingStep2 {
    margin-top: 1rem;
}

.DatastoreScalingStep2Info {
    color: rgba(0, 0, 0, 0.45);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.DatastoreScalingStep2SliderRow {
    justify-content: center;
    min-height: 5rem;
}

.DatastoreScalingStep2Region {
    margin-top: 2rem;
}

.DatastoreScalingStep2NodeInfo {
    margin-top: 3rem;
}


.DatastoreScalingNewNode{
    visibility: hidden;
    width: 100%;
}

.DatastoreScalingStep2Alert{
    margin-top: 2rem;
}
@hack: true; @import "/src/src/antd-default-theme.less";