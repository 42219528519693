.AdminDataStores {
    padding: @padding-lg;
    background-color: @white;
}

.AdminDataStoresClusterId{
    display: flex;
    justify-content: space-between;
}

.AdminDataStoresClusterName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 20rem;
    margin-left: 1rem;
    font-size: medium;
    font-weight: 500;
}
@hack: true; @import "/src/src/antd-default-theme.less";