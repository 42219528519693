.AppFormInput {
    border-radius: @border-radius-base-x2;
    color: @ccxFormGray;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
        0.1s padding ease-in-out;
    -webkit-appearance: none;

    div {
        border-radius: @border-radius-base-x2 !important;
    }
}

.AppFormInputNumber {
    border-radius: @border-radius-base-x2;
    color: @ccxFormGray;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
        0.1s padding ease-in-out;
    -webkit-appearance: none;
    width: 100%;

    input {
        text-align: right;
        padding-right: 40px;
    }
}

.AppFormInputNumberLeft {
    border-radius: @border-radius-base-x2;
    color: @ccxFormGray;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
        0.1s padding ease-in-out;
    -webkit-appearance: none;
    width: 100%;
}

.AppFormInput:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.AppFormItemWithTips {
    margin-bottom: 5px;
}

.AppFormRadioGroupHorizontal {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 18px;
}

.AppFormRadioGroupItemHorizontal {
    height: 100% !important;
    padding: 0 !important;
    margin: 0;
    align-items: center;
    margin-right: 10px !important;
    &:last-child {
        margin-right: 0 !important;
    }
}

.AppFormRadioGroupItemVertical {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    padding: 0;
    margin: 0;
    align-items: center;
    margin-bottom: 5px;
}

.AppFormRadioGroupVertical {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
}

.AppFormRadioGroup {
    width: 100%;
}

.AppFormRadioGroup label {
    width: 100%;
}

.AppRow {
    display: flex;
    flex-flow: row wrap;
    > * {
        flex-basis: 45%;
        margin: 10px;
    }
}

.AppFormTipIcon {
    margin-left: 5px;
}

@hack: true; @import "/src/src/antd-default-theme.less";