.ButtonModalFormButton {
    margin: 10px 20px;
}

.ButtonModalFormButtonLeft {
    padding-left: 50px;
    text-align: left;
}

.ButtonModalFormButtonRight {
    padding-right: 50px;
    text-align: right;
}

.ButtonModalFormTypeSimple {
    text-align: left;
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}

.ButtonModalFormTypeSimple:hover {
    background-color: transparent;
    text-decoration: none;
}

@hack: true; @import "/src/src/antd-default-theme.less";