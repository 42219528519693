.DataStore {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.DatastoreSkeleton {
    padding: 24px;
    background: @ccxWhite;
    border-radius: @border-radius-base-x2;
}

.DataStoreTabs > *:last-child {
    background: @ccxWhite;
    padding: 24px;
}

.DataStoreBackRow {
    margin-top: 10px;
    button {
        width: 100%;
    }
}

.DataStoreTabs > div > div {
    overflow: visible !important;
}

.TabsBadge {
    position: absolute;
    z-index: 1;
    top: -10px;
    left: calc(100% - 14px);
}

@hack: true; @import "/src/src/antd-default-theme.less";