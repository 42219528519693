.AddServiceCreateVPCStepContainer {
    margin: 20px;

    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: @ccxBlack;
        text-align: center;
        margin-bottom: 30px;
    }
}

.AddServiceCreateVPCConfiguration {
    width: 50%;
}

.AddServiceCreateVPCStepDivider {
    margin-bottom: 0;
}

.AddServiceCreateVPCStepCol {
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
}

.AddServiceCreateVPCStepRow {
    margin-bottom: 5px;
}

.AddServiceCreateVPCStepFlag {
    margin-right: 5px;
}

@hack: true; @import "/src/src/antd-default-theme.less";