.DataStoreOverviewQueriesPerSecond {
    border-radius: @border-radius-base-x2;
    border: 1px solid @ccxCardBorder;
    min-height: 190px;

    header {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        padding: 24px 24px 8px 24px;
        font-weight: bold;
    }

    main {
        padding: 0 24px 24px 24px;
        font-size: 12px;
        line-height: 20px;
        font-weight: normal;
        opacity: 0.85;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";