.AppGridTable {
    background: @ccxWhite;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.AppGridTable > * {
    flex-basis: '100%';
}

.AppGridTableHeader {
    font-size: 14px;
    color: @ccxGridTableHeaderTextColor;
    display: flex;
}

.AppGridTableHeaderCol {
    padding: 0 16px;
}

.AppGridTableDataRow {
    background: @ccxWhite;
    box-sizing: border-box;
    border-radius: @border-radius-base-x2;
    padding: 8px 0;
    display: flex;
    min-height: 96px;
}

.AppGridTableDataRowNormal {
    border: 1px solid @ccxGridTableRowBorderColor;
}

.AppGridTableDataRowAlert {
    border: 1px solid @ccxRed;
}

.AppGridTableDataRow > *:last-child {
    border-right: none;
    padding: 0;
}

.AppGridTableDataRowDisabled {
    opacity: 0.5;
}

.AppGridTableDataCol {
    border-right: 1px solid @ccxGridTableRowBorderColor;
    padding: 0 16px;
    display: flex;
    align-items: center;
}

.AppGridTableDataColDisabled {
    opacity: 0.85;
}

.AppGridTableDataColCenter {
    justify-content: center;
}

.AppGridTableDataColStart {
    justify-content: flex-start;
}

.AppGridTableDataColEnd {
    justify-content: flex-end;
}

@hack: true; @import "/src/src/antd-default-theme.less";