.DataStoreOverviewAccessToServices{
    border-radius: @border-radius-base-x2;
    border: 1px solid @ccxCardBorder;
    margin-bottom: 1rem;
    padding: 1rem;

    header {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        padding: .75rem;
        font-weight: bold;
    }
}

.DataStoreOverviewAccessToServicesRole{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.DataStoreOverviewAccessToServicesConInfo:first-child{
    padding-bottom: 1rem;
}

.DataStoreOverviewAccessToServicesRoleName{
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;
}
@hack: true; @import "/src/src/antd-default-theme.less";