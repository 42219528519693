.DataStoreInformationTop > *:nth-child(1) {
    font-size: 16px;
    line-height: 24px;
    gap: 0;
}

.DataStoreInformationMiddle {
    width: max-content;
}

.DataStoreInformationTopName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bolder;
}

.DataStoreInformationTopLongName{
    width: 13rem;
}
@hack: true; @import "/src/src/antd-default-theme.less";