.CardContentAvailabilityZoneMulti {
    display: flex;
    gap: 16px;
}

.CardContentAvailabilityZoneMulti > * {
    flex-basis: 100%;
    border-radius: @border-radius-base-x2;
    border: 1px solid @ccxCardBorder;
}

.CardContentAvailabilityZoneMultiTitle {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    height: 54px;
    padding: 16px 25px;
}

.CardContentAvailabilityZoneMultiTitleSelect {
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    padding: 0px 25px 5px 0px;
}

.CardContentAvailabilityZoneMultiRadioGroup {
    display: flex;
    flex-direction: column;

    label {
        text-align: center;
        font-size: 12px !important;
    }
}

.CardContentAvailabilityZoneMultiSelect {
    display: flex;
    gap: 16px;
}

.CardContentAvailabilityZoneMultiSelect > * {
    flex-basis: 100%;
    border-radius: @border-radius-base-x2;
}

@hack: true; @import "/src/src/antd-default-theme.less";