.WizardFormConfigurationStep5Col {
    display: flex;
    justify-content: end;
    margin-bottom: 5px;
}

.WizardFormConfigurationStep5Row {
    margin-bottom: 5px;
}

strong {
    margin-right: 4px;
}

.WizardFormConfigurationStep5Flag {
    margin-right: 5px;
}

@hack: true; @import "/src/src/antd-default-theme.less";