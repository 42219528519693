.DataStoreSettingsNameChange {
    h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.85;
    }

    input {
        border-radius: @border-radius-base-x2;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";