.FirewallsCollapse {
    background-color: transparent;
}

.FirewallsCollapsePanel {
    border-bottom: 1px solid transparent;
}

.FirewallsOptionsColumn {
    text-align: center;
}

.FirewallsTable {
    margin-top: 0.5rem;
}

@hack: true; @import "/src/src/antd-default-theme.less";